import { ITableCellValue, ITableData } from '@components/table/Table';

import { Verticals } from '@/common/interfaces';
import { displayCurrency, displayDate, displayUTCDate, displayUuid } from '@/common/utils';
import { displayNumberWithDecimals } from '@/common/utils/utils';
import i18n from '@/i18n';

import { IInvoiceData, IInvoicesRawData } from './types';

export const buildInvoicesTable = (data: IInvoicesRawData, activeVertical: Verticals): ITableData => {
  const isFuel = activeVertical === Verticals.Fuel;
  const isGrocery = activeVertical === Verticals.Grocery;
  const rows: Array<string | number | ITableCellValue>[] = data.records.map((item: IInvoiceData) => {
    const status =
      item.status === 'complete' && item.is_payment_completed
        ? i18n.t('invoicesPage.invoicesTbl.paid')
        : i18n.t('invoicesPage.invoicesTbl.unpaid');

    const period = `${displayUTCDate(item.period_start_timestamp, 'numericShort')} - ${
      item.period_end_timestamp ? displayUTCDate(item.period_end_timestamp, 'numericShort') : 'Present'
    }`;

    return [
      displayDate(item.posted_date),
      period,
      {
        value: displayUuid(item.invoice_uuid),
        rawValue: item.invoice_uuid,
      },
      item.billable_entity_display_name,
      status,
      ...(isFuel
        ? [
            {
              value: item.incremental_gallons > 0 ? displayNumberWithDecimals(item.incremental_gallons, 0) : '--',
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuel
        ? [
            {
              value: item.incremental_cstore_sales > 0 ? displayCurrency(item.incremental_cstore_sales) : '--',
              isNumeric: true,
            },
          ]
        : []),
      // The incremental profit column is only displayed for non-grocery verticals
      // because the user incremental profit calculation is not yet implemented on the backend.
      ...(isGrocery
        ? []
        : [
            {
              value: item.incremental_profit > 0 ? displayCurrency(item.incremental_profit) : '--',
              isNumeric: true,
            },
          ]),
      {
        value: item.invoice_amount > 0 ? displayCurrency(item.invoice_amount) : '--',
        isNumeric: true,
      },
      {
        value: '',
        invoiceUuid: item.invoice_uuid,
        popoverLink: true,
      },
    ];
  });

  const result: ITableData = {
    headers: [
      { columnName: i18n.t('invoicesPage.invoicesTbl.invoicePosted') },
      { columnName: i18n.t('invoicesPage.invoicesTbl.invoicePeriod') },
      { columnName: i18n.t('invoicesPage.invoicesTbl.invoiceId') },
      { columnName: i18n.t('invoicesPage.invoicesTbl.account') },
      { columnName: i18n.t('invoicesPage.invoicesTbl.status') },
      ...(isFuel
        ? [
            {
              columnName: i18n.t('invoicesPage.invoicesTbl.incGallons'),
              isNumeric: true,
              help: i18n.t('invoicesPage.invoicesTbl.incGallonsHelp'),
            },
          ]
        : []),
      ...(isFuel
        ? [
            {
              columnName: i18n.t('invoicesPage.invoicesTbl.incCStoreSales'),
              isNumeric: true,
              help: i18n.t('invoicesPage.invoicesTbl.incCStoreSalesHelp'),
            },
          ]
        : []),
      ...(isGrocery
        ? []
        : [
            {
              columnName: i18n.t('invoicesPage.invoicesTbl.incProfit'),
              isNumeric: true,
              help: i18n.t('invoicesPage.invoicesTbl.incProfitHelp'),
            },
          ]),
      {
        columnName: i18n.t('invoicesPage.invoicesTbl.invoiceAmount'),
        isNumeric: true,
      },
      { columnName: i18n.t('invoicesPage.invoicesTbl.actions') },
    ],
    rows: rows,
  };

  return result;
};
